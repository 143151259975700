export default {
  data() {
    return {
      features: [
        {
          standard_text_color: "c_dark_2",
          pro_text_color: "c_dark_2",
          enterprise_text_color: "c_dark_2",
          standard_icon: require("@/assets/images/icon-square-1.png"),
          pro_icon: require("@/assets/images/icon-square-2.png"),
          enterprise_icon: require("@/assets/images/icon-square-3.png"),
          icon_as_image: true,
          bold_standard: false,
          bold_pro: false,
          bold_enterprise: false,
          standard_icon_color: null,
          pro_icon_color: null,
          enterprise_icon_color: null,
          name: this.$trans("plans_feature_name_19"),
          standard: this.$trans("plans_feature_standard_19"),
          pro: this.$trans("plans_feature_pro_19"),
          enterprise: this.$trans("plans_feature_enterprise_19"),
          tooltip_standard: this.$trans("plans_feature_standard_19_tooltip"),
          tooltip_pro: this.$trans("plans_feature_pro_19_tooltip"),
          tooltip_enterprise: this.$trans(
            "plans_feature_enterprise_19_tooltip",
          ),
        },
        {
          standard_text_color: "c_dark_2",
          pro_text_color: "c_dark_2",
          enterprise_text_color: "c_dark_2",
          standard_icon: require("@/assets/images/icon-square-1.png"),
          pro_icon: require("@/assets/images/icon-square-2.png"),
          enterprise_icon: require("@/assets/images/icon-square-3.png"),
          icon_as_image: true,
          bold_standard: false,
          bold_pro: false,
          bold_enterprise: false,
          standard_icon_color: null,
          pro_icon_color: null,
          enterprise_icon_color: null,
          name: this.$trans("plans_feature_name_18"),
          standard: this.$trans("plans_feature_standard_18"),
          pro: this.$trans("plans_feature_pro_18"),
          enterprise: this.$trans("plans_feature_enterprise_18"),
          tooltip_standard: this.$trans("plans_feature_standard_18_tooltip"),
          tooltip_pro: this.$trans("plans_feature_pro_18_tooltip"),
          tooltip_enterprise: this.$trans(
            "plans_feature_enterprise_18_tooltip",
          ),
        },
        {
          standard_text_color: "c_dark_2",
          pro_text_color: "c_dark_2",
          enterprise_text_color: "c_dark_2",
          standard_icon: "$check-box-outline",
          pro_icon: "$check-box-outline",
          enterprise_icon: "$check-box-outline",
          icon_as_image: false,
          bold_standard: false,
          bold_pro: false,
          bold_enterprise: false,
          standard_icon_color: "c_green",
          pro_icon_color: "c_green",
          enterprise_icon_color: "c_green",
          name: this.$trans("plans_feature_name_11"),
          enterprise: this.$trans("plans_feature_enterprise_11"),
          standard: this.$trans("plans_feature_standard_11"),
          pro: this.$trans("plans_feature_pro_11"),
          tooltip_enterprise: this.$trans(
            "plans_feature_enterprise_11_tooltip",
          ),
          tooltip_standard: this.$trans("plans_feature_standard_11_tooltip"),
          tooltip_pro: this.$trans("plans_feature_pro_11_tooltip"),
        },
        {
          standard_text_color: "c_dark_2",
          pro_text_color: "c_dark_2",
          enterprise_text_color: "c_dark_2",
          standard_icon: "$check-box-outline",
          pro_icon: "$check-box-outline",
          enterprise_icon: "$check-box-outline",
          icon_as_image: false,
          bold_standard: false,
          bold_pro: false,
          bold_enterprise: false,
          standard_icon_color: "c_green",
          pro_icon_color: "c_green",
          enterprise_icon_color: "c_green",
          name: this.$trans("plans_feature_name_26"),
          enterprise: this.$trans("plans_feature_enterprise_26"),
          standard: this.$trans("plans_feature_standard_26"),
          pro: this.$trans("plans_feature_pro_26"),
          tooltip_enterprise: this.$trans(
            "plans_feature_enterprise_26_tooltip",
          ),
          tooltip_standard: this.$trans("plans_feature_standard_26_tooltip"),
          tooltip_pro: this.$trans("plans_feature_pro_26_tooltip"),
        },
        {
          standard_text_color: "c_dark_2",
          pro_text_color: "c_dark_2",
          enterprise_text_color: "c_dark_2",
          standard_icon: "$check-box-outline",
          pro_icon: "$check-box-outline",
          enterprise_icon: "$check-box-outline",
          icon_as_image: false,
          bold_standard: false,
          bold_pro: false,
          bold_enterprise: false,
          standard_icon_color: "c_green",
          pro_icon_color: "c_green",
          enterprise_icon_color: "c_green",
          name: this.$trans("plans_feature_name_3"),
          standard: this.$trans("plans_feature_standard_3"),
          pro: this.$trans("plans_feature_pro_3"),
          enterprise: this.$trans("plans_feature_enterprise_3"),
          tooltip_standard: this.$trans("plans_feature_standard_3_tooltip"),
          tooltip_pro: this.$trans("plans_feature_pro_3_tooltip"),
          tooltip_enterprise: this.$trans("plans_feature_enterprise_3_tooltip"),
        },
        {
          standard_text_color: "c_dark_2",
          pro_text_color: "c_dark_2",
          enterprise_text_color: "c_dark_2",
          standard_icon: require("@/assets/images/icon-square-1.png"),
          pro_icon: require("@/assets/images/icon-square-2.png"),
          enterprise_icon: require("@/assets/images/icon-square-3.png"),
          icon_as_image: true,
          bold_standard: false,
          bold_pro: false,
          bold_enterprise: false,
          standard_icon_color: null,
          pro_icon_color: null,
          enterprise_icon_color: null,
          name: this.$trans("plans_feature_name_2"),
          standard: this.$trans("plans_feature_standard_2"),
          pro: this.$trans("plans_feature_pro_2"),
          enterprise: this.$trans("plans_feature_enterprise_2"),
          tooltip_standard: this.$trans("plans_feature_standard_2_tooltip"),
          tooltip_pro: this.$trans("plans_feature_pro_2_tooltip"),
          tooltip_enterprise: this.$trans("plans_feature_enterprise_2_tooltip"),
        },
        {
          standard_text_color: "c_dark_2",
          pro_text_color: "c_dark_2",
          enterprise_text_color: "c_dark_2",
          standard_icon: require("@/assets/images/icon-square-1.png"),
          pro_icon: require("@/assets/images/icon-square-2.png"),
          enterprise_icon: require("@/assets/images/icon-square-3.png"),
          icon_as_image: true,
          bold_standard: false,
          bold_pro: false,
          bold_enterprise: false,
          standard_icon_color: "c_green",
          pro_icon_color: "c_green",
          enterprise_icon_color: "c_green",
          name: this.$trans("plans_feature_name_27"),
          standard: this.$trans("plans_feature_standard_27"),
          pro: this.$trans("plans_feature_pro_27"),
          enterprise: this.$trans("plans_feature_enterprise_27"),
          tooltip_standard: this.$trans("plans_feature_standard_27_tooltip"),
          tooltip_pro: this.$trans("plans_feature_pro_27_tooltip"),
          tooltip_enterprise: this.$trans(
            "plans_feature_enterprise_27_tooltip",
          ),
        },
        {
          standard_text_color: "c_dark_2",
          pro_text_color: "c_dark_2",
          enterprise_text_color: "c_dark_2",
          standard_icon: require("@/assets/images/icon-square-1.png"),
          pro_icon: require("@/assets/images/icon-square-2.png"),
          enterprise_icon: require("@/assets/images/icon-square-3.png"),
          icon_as_image: true,
          bold_standard: false,
          bold_pro: false,
          bold_enterprise: false,
          standard_icon_color: "c_green",
          pro_icon_color: "c_green",
          enterprise_icon_color: "c_green",
          name: this.$trans("plans_feature_name_5"),
          standard: this.$trans("plans_feature_standard_5"),
          pro: this.$trans("plans_feature_pro_5"),
          enterprise: this.$trans("plans_feature_enterprise_5"),
          tooltip_standard: this.$trans("plans_feature_standard_5_tooltip"),
          tooltip_pro: this.$trans("plans_feature_pro_5_tooltip"),
          tooltip_enterprise: this.$trans("plans_feature_enterprise_5_tooltip"),
        },
        {
          standard_text_color: "c_dark_2",
          pro_text_color: "c_dark_2",
          enterprise_text_color: "c_dark_2",
          standard_icon: require("@/assets/images/icon-square-1.png"),
          pro_icon: require("@/assets/images/icon-square-2.png"),
          enterprise_icon: require("@/assets/images/icon-square-3.png"),
          icon_as_image: true,
          bold_standard: false,
          bold_pro: false,
          bold_enterprise: false,
          standard_icon_color: "c_green",
          pro_icon_color: "c_green",
          enterprise_icon_color: "c_green",
          name: this.$trans("plans_feature_name_7"),
          enterprise: this.$trans("plans_feature_enterprise_7"),
          standard: this.$trans("plans_feature_standard_7"),
          pro: this.$trans("plans_feature_pro_7"),
          tooltip_enterprise: this.$trans("plans_feature_enterprise_7_tooltip"),
          tooltip_standard: this.$trans("plans_feature_standard_7_tooltip"),
          tooltip_pro: this.$trans("plans_feature_pro_7_tooltip"),
        },
        {
          standard_text_color: "c_dark_2",
          pro_text_color: "c_dark_2",
          enterprise_text_color: "c_dark_2",
          standard_icon: "$check-box-outline",
          pro_icon: "$check-box-outline",
          enterprise_icon: "$check-box-outline",
          icon_as_image: false,
          bold_standard: false,
          bold_pro: false,
          bold_enterprise: false,
          standard_icon_color: "c_green",
          pro_icon_color: "c_green",
          enterprise_icon_color: "c_green",
          name: this.$trans("plans_feature_name_6"),
          enterprise: this.$trans("plans_feature_enterprise_6"),
          standard: this.$trans("plans_feature_standard_6"),
          pro: this.$trans("plans_feature_pro_6"),
          tooltip_enterprise: this.$trans("plans_feature_enterprise_6_tooltip"),
          tooltip_standard: this.$trans("plans_feature_standard_6_tooltip"),
          tooltip_pro: this.$trans("plans_feature_pro_6_tooltip"),
        },
        {
          standard_text_color: "c_dark_2",
          pro_text_color: "c_dark_2",
          enterprise_text_color: "c_dark_2",
          standard_icon: "$check-box-outline",
          pro_icon: "$check-box-outline",
          enterprise_icon: "$check-box-outline",
          icon_as_image: false,
          bold_standard: false,
          bold_pro: false,
          bold_enterprise: false,
          standard_icon_color: "c_green",
          pro_icon_color: "c_green",
          enterprise_icon_color: "c_green",
          name: this.$trans("plans_feature_name_8"),
          enterprise: this.$trans("plans_feature_enterprise_8"),
          standard: this.$trans("plans_feature_standard_8"),
          pro: this.$trans("plans_feature_pro_8"),
          tooltip_enterprise: this.$trans("plans_feature_enterprise_8_tooltip"),
          tooltip_standard: this.$trans("plans_feature_standard_8_tooltip"),
          tooltip_pro: this.$trans("plans_feature_pro_8_tooltip"),
        },
        {
          standard_text_color: "c_dark_2",
          pro_text_color: "c_dark_2",
          enterprise_text_color: "c_dark_2",
          standard_icon: "$check-box-outline",
          pro_icon: "$check-box-outline",
          enterprise_icon: "$check-box-outline",
          icon_as_image: false,
          bold_standard: false,
          bold_pro: false,
          bold_enterprise: false,
          standard_icon_color: "c_green",
          pro_icon_color: "c_green",
          enterprise_icon_color: "c_green",
          name: this.$trans("plans_feature_name_10"),
          standard: this.$trans("plans_feature_standard_10"),
          pro: this.$trans("plans_feature_pro_10"),
          enterprise: this.$trans("plans_feature_enterprise_10"),
          tooltip_standard: this.$trans("plans_feature_standard_10_tooltip"),
          tooltip_pro: this.$trans("plans_feature_pro_10_tooltip"),
          tooltip_enterprise: this.$trans(
            "plans_feature_enterprise_10_tooltip",
          ),
        },
        {
          standard_text_color: "c_dark_2",
          pro_text_color: "c_dark_2",
          enterprise_text_color: "c_dark_2",
          standard_icon: "$check-box-outline",
          pro_icon: "$check-box-outline",
          enterprise_icon: "$check-box-outline",
          icon_as_image: false,
          bold_standard: false,
          bold_pro: false,
          bold_enterprise: false,
          standard_icon_color: "c_green",
          pro_icon_color: "c_green",
          enterprise_icon_color: "c_green",
          name: this.$trans("plans_feature_name_14"),
          standard: this.$trans("plans_feature_standard_14"),
          pro: this.$trans("plans_feature_pro_14"),
          enterprise: this.$trans("plans_feature_enterprise_14"),
          tooltip_standard: this.$trans("plans_feature_standard_14_tooltip"),
          tooltip_pro: this.$trans("plans_feature_pro_14_tooltip"),
          tooltip_enterprise: this.$trans(
            "plans_feature_enterprise_14_tooltip",
          ),
        },
        {
          standard_text_color: "c_dark_2",
          pro_text_color: "c_dark_2",
          enterprise_text_color: "c_dark_2",
          standard_icon: "$check-box-outline",
          pro_icon: "$check-box-outline",
          enterprise_icon: "$check-box-outline",
          icon_as_image: false,
          bold_standard: false,
          bold_pro: false,
          bold_enterprise: false,
          standard_icon_color: "c_green",
          pro_icon_color: "c_green",
          enterprise_icon_color: "c_green",
          name: this.$trans("plans_feature_name_20"),
          standard: this.$trans("plans_feature_standard_20"),
          pro: this.$trans("plans_feature_pro_20"),
          enterprise: this.$trans("plans_feature_enterprise_20"),
          tooltip_standard: this.$trans("plans_feature_standard_20_tooltip"),
          tooltip_pro: this.$trans("plans_feature_pro_20_tooltip"),
          tooltip_enterprise: this.$trans(
            "plans_feature_enterprise_20_tooltip",
          ),
        },
        {
          standard_text_color: "c_dark_2",
          pro_text_color: "c_dark_2",
          enterprise_text_color: "c_dark_2",
          standard_icon: "$check-box-outline",
          pro_icon: "$check-box-outline",
          enterprise_icon: "$check-box-outline",
          icon_as_image: false,
          bold_standard: false,
          bold_pro: false,
          bold_enterprise: false,
          standard_icon_color: "c_green",
          pro_icon_color: "c_green",
          enterprise_icon_color: "c_green",
          name: this.$trans("plans_feature_name_12"),
          standard: this.$trans("plans_feature_standard_12"),
          pro: this.$trans("plans_feature_pro_12"),
          enterprise: this.$trans("plans_feature_enterprise_12"),
          tooltip_standard: this.$trans("plans_feature_standard_12_tooltip"),
          tooltip_pro: this.$trans("plans_feature_pro_12_tooltip"),
          tooltip_enterprise: this.$trans(
            "plans_feature_enterprise_12_tooltip",
          ),
        },
        {
          standard_text_color: "c_dark_2",
          pro_text_color: "c_dark_2",
          enterprise_text_color: "c_dark_2",
          standard_icon: "$check-box-outline",
          pro_icon: "$check-box-outline",
          enterprise_icon: "$check-box-outline",
          icon_as_image: false,
          bold_standard: false,
          bold_pro: false,
          bold_enterprise: false,
          standard_icon_color: "c_green",
          pro_icon_color: "c_green",
          enterprise_icon_color: "c_green",
          name: this.$trans("plans_feature_name_21"),
          standard: this.$trans("plans_feature_standard_21"),
          pro: this.$trans("plans_feature_pro_21"),
          enterprise: this.$trans("plans_feature_enterprise_21"),
          tooltip_standard: this.$trans("plans_feature_standard_21_tooltip"),
          tooltip_pro: this.$trans("plans_feature_pro_21_tooltip"),
          tooltip_enterprise: this.$trans(
            "plans_feature_enterprise_21_tooltip",
          ),
        },
        {
          standard_text_color: "c_dark_2",
          pro_text_color: "c_dark_2",
          enterprise_text_color: "c_dark_2",
          standard_icon: "$check-box-outline",
          pro_icon: "$check-box-outline",
          enterprise_icon: "$check-box-outline",
          icon_as_image: false,
          bold_standard: false,
          bold_pro: false,
          bold_enterprise: false,
          standard_icon_color: "c_green",
          pro_icon_color: "c_green",
          enterprise_icon_color: "c_green",
          name: this.$trans("plans_feature_name_23"),
          standard: this.$trans("plans_feature_standard_23"),
          pro: this.$trans("plans_feature_pro_23"),
          enterprise: this.$trans("plans_feature_enterprise_23"),
          tooltip_standard: this.$trans("plans_feature_standard_23_tooltip"),
          tooltip_pro: this.$trans("plans_feature_pro_23_tooltip"),
          tooltip_enterprise: this.$trans(
            "plans_feature_enterprise_23_tooltip",
          ),
        },
        {
          standard_text_color: "c_dark_2",
          pro_text_color: "c_dark_2",
          enterprise_text_color: "c_dark_2",
          standard_icon: "$check-box-outline",
          pro_icon: "$check-box-outline",
          enterprise_icon: "$check-box-outline",
          icon_as_image: false,
          bold_standard: false,
          bold_pro: false,
          bold_enterprise: false,
          standard_icon_color: "c_green",
          pro_icon_color: "c_green",
          enterprise_icon_color: "c_green",
          name: this.$trans("plans_feature_name_28"),
          standard: this.$trans("plans_feature_standard_28"),
          pro: this.$trans("plans_feature_pro_28"),
          enterprise: this.$trans("plans_feature_enterprise_28"),
          tooltip_standard: this.$trans("plans_feature_standard_28_tooltip"),
          tooltip_pro: this.$trans("plans_feature_pro_28_tooltip"),
          tooltip_enterprise: this.$trans(
            "plans_feature_enterprise_28_tooltip",
          ),
        },
        {
          standard_text_color: "c_dark_2",
          pro_text_color: "c_dark_2",
          enterprise_text_color: "c_dark_2",
          standard_icon: "$check-box-outline",
          pro_icon: "$check-box-outline",
          enterprise_icon: "$check-box-outline",
          icon_as_image: false,
          bold_standard: false,
          bold_pro: false,
          bold_enterprise: false,
          standard_icon_color: "c_green",
          pro_icon_color: "c_green",
          enterprise_icon_color: "c_green",
          name: this.$trans("plans_feature_name_29"),
          standard: this.$trans("plans_feature_standard_29"),
          pro: this.$trans("plans_feature_pro_29"),
          enterprise: this.$trans("plans_feature_enterprise_29"),
          tooltip_standard: this.$trans("plans_feature_standard_29_tooltip"),
          tooltip_pro: this.$trans("plans_feature_pro_29_tooltip"),
          tooltip_enterprise: this.$trans(
            "plans_feature_enterprise_29_tooltip",
          ),
        },
        {
          standard_text_color: "c_dark_2",
          pro_text_color: "c_dark_2",
          enterprise_text_color: "c_dark_2",
          standard_icon: "$check-box-outline",
          pro_icon: "$check-box-outline",
          enterprise_icon: "$check-box-outline",
          icon_as_image: false,
          bold_standard: false,
          bold_pro: false,
          bold_enterprise: false,
          standard_icon_color: "c_green",
          pro_icon_color: "c_green",
          enterprise_icon_color: "c_green",
          name: this.$trans("plans_feature_name_30"),
          standard: this.$trans("plans_feature_standard_30"),
          pro: this.$trans("plans_feature_pro_30"),
          enterprise: this.$trans("plans_feature_enterprise_30"),
          tooltip_standard: this.$trans("plans_feature_standard_30_tooltip"),
          tooltip_pro: this.$trans("plans_feature_pro_30_tooltip"),
          tooltip_enterprise: this.$trans(
            "plans_feature_enterprise_30_tooltip",
          ),
        },
        {
          standard_text_color: "c_dark_2",
          pro_text_color: "c_dark_2",
          enterprise_text_color: "c_dark_2",
          standard_icon: "$check-box-outline",
          pro_icon: "$check-box-outline",
          enterprise_icon: "$check-box-outline",
          icon_as_image: false,
          bold_standard: false,
          bold_pro: false,
          bold_enterprise: false,
          standard_icon_color: "c_green",
          pro_icon_color: "c_green",
          enterprise_icon_color: "c_green",
          name: this.$trans("plans_feature_name_31"),
          standard: this.$trans("plans_feature_standard_31"),
          pro: this.$trans("plans_feature_pro_31"),
          enterprise: this.$trans("plans_feature_enterprise_31"),
          tooltip_standard: this.$trans("plans_feature_standard_31_tooltip"),
          tooltip_pro: this.$trans("plans_feature_pro_31_tooltip"),
          tooltip_enterprise: this.$trans(
            "plans_feature_enterprise_31_tooltip",
          ),
        },
        {
          standard_text_color: "c_dark_2",
          pro_text_color: "c_dark_2",
          enterprise_text_color: "c_dark_2",
          standard_icon: "$check-box-outline",
          pro_icon: "$check-box-outline",
          enterprise_icon: "$check-box-outline",
          icon_as_image: false,
          bold_standard: false,
          bold_pro: false,
          bold_enterprise: false,
          standard_icon_color: "c_green",
          pro_icon_color: "c_green",
          enterprise_icon_color: "c_green",
          name: this.$trans("plans_feature_name_22"),
          standard: this.$trans("plans_feature_standard_22"),
          pro: this.$trans("plans_feature_pro_22"),
          enterprise: this.$trans("plans_feature_enterprise_22"),
          tooltip_standard: this.$trans("plans_feature_standard_22_tooltip"),
          tooltip_pro: this.$trans("plans_feature_pro_22_tooltip"),
          tooltip_enterprise: this.$trans(
            "plans_feature_enterprise_22_tooltip",
          ),
        },
        {
          standard_text_color: "c_gray_4",
          pro_text_color: "c_dark_2",
          enterprise_text_color: "c_dark_2",
          standard_icon: "$close-box-outline",
          pro_icon: "$check-box-outline",
          enterprise_icon: "$check-box-outline",
          icon_as_image: false,
          bold_standard: false,
          bold_pro: false,
          bold_enterprise: false,
          standard_icon_color: "c_gray_4",
          pro_icon_color: "c_green",
          enterprise_icon_color: "c_green",
          name: this.$trans("plans_feature_name_4"),
          standard: this.$trans("plans_feature_standard_4"),
          pro: this.$trans("plans_feature_pro_4"),
          enterprise: this.$trans("plans_feature_enterprise_4"),
          tooltip_standard: this.$trans("plans_feature_standard_4_tooltip"),
          tooltip_pro: this.$trans("plans_feature_pro_4_tooltip"),
          tooltip_enterprise: this.$trans("plans_feature_enterprise_4_tooltip"),
        },
        {
          standard_text_color: "c_gray_4",
          pro_text_color: "c_dark_2",
          enterprise_text_color: "c_dark_2",
          standard_icon: "$close-box-outline",
          pro_icon: "$check-box-outline",
          enterprise_icon: "$check-box-outline",
          icon_as_image: false,
          bold_standard: false,
          bold_pro: false,
          bold_enterprise: false,
          standard_icon_color: "c_gray_4",
          pro_icon_color: "c_green",
          enterprise_icon_color: "c_green",
          name: this.$trans("plans_feature_name_32"),
          enterprise: this.$trans("plans_feature_enterprise_32"),
          standard: this.$trans("plans_feature_standard_32"),
          pro: this.$trans("plans_feature_pro_32"),
          tooltip_enterprise: this.$trans(
            "plans_feature_enterprise_32_tooltip",
          ),
          tooltip_standard: this.$trans("plans_feature_standard_32_tooltip"),
          tooltip_pro: this.$trans("plans_feature_pro_32_tooltip"),
        },
        {
          standard_text_color: "c_gray_4",
          pro_text_color: "c_dark_2",
          enterprise_text_color: "c_dark_2",
          standard_icon: "$close-box-outline",
          pro_icon: "$check-box-outline",
          enterprise_icon: "$check-box-outline",
          icon_as_image: false,
          bold_standard: false,
          bold_pro: false,
          bold_enterprise: false,
          standard_icon_color: "c_gray_4",
          pro_icon_color: "c_green",
          enterprise_icon_color: "c_green",
          name: this.$trans("plans_feature_name_24"),
          standard: this.$trans("plans_feature_standard_24"),
          pro: this.$trans("plans_feature_pro_24"),
          enterprise: this.$trans("plans_feature_enterprise_24"),
          tooltip_standard: this.$trans("plans_feature_standard_24_tooltip"),
          tooltip_pro: this.$trans("plans_feature_pro_24_tooltip"),
          tooltip_enterprise: this.$trans(
            "plans_feature_enterprise_24_tooltip",
          ),
        },
        {
          standard_text_color: "c_gray_4",
          pro_text_color: "c_dark_2",
          enterprise_text_color: "c_dark_2",
          standard_icon: "$close-box-outline",
          pro_icon: "$check-box-outline",
          enterprise_icon: "$check-box-outline",
          icon_as_image: false,
          bold_standard: false,
          bold_pro: false,
          bold_enterprise: false,
          standard_icon_color: "c_gray_4",
          pro_icon_color: "c_green",
          enterprise_icon_color: "c_green",
          name: this.$trans("plans_feature_name_25"),
          standard: this.$trans("plans_feature_standard_25"),
          pro: this.$trans("plans_feature_pro_25"),
          enterprise: this.$trans("plans_feature_enterprise_25"),
          tooltip_standard: this.$trans("plans_feature_standard_25_tooltip"),
          tooltip_pro: this.$trans("plans_feature_pro_25_tooltip"),
          tooltip_enterprise: this.$trans(
            "plans_feature_enterprise_25_tooltip",
          ),
        },
      ],
    };
  },
};
